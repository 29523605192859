#offcanvasNavbarCategory {
    width: 300px !important;

    .offcanvas-body {
        .dropdown-menu {
            border: none;
            padding-left: 1rem;
            margin-top: 0;

            .dropdown-item {
                border-radius: 5px;
                padding: .25rem 1rem;
            }
        }

        .nav-link {
            padding: .25rem 1rem;
            border-radius: 5px;
            text-align: left;
        }

        .nav-link.active {
            background-color: #84be38;
            color: #fff !important;
        }

        .nav-link:hover {
            background-color: #e9ecef;
            color: #212529;
        }
    }

}

#page_products_categorys {
    .card {
        .card-body {
            background-color: #84be38;
            color: #fff;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            .dropdown-menu {
                border: none;
                padding-left: 1rem;
                margin-top: 0;

                .dropdown-item {
                    border-radius: 5px;
                    padding: .25rem 1rem;
                }
            }

            .nav-link {
                padding: .6rem 1rem;
                color: #fff;
                border-bottom: 1px solid #fff;
            }

            .nav-link.active {
                background-color: #1d8375;
                color: #fff;
            }

            .nav-link:hover {
                background-color: #e9ecef;
                color: #212529;
            }
        }
    }

}

#page_home_products {
    div#page_home_filter {
        background-color: #fff;

        .navbar-toggler-icon {
            margin-top: 0;
        }

        .filter-category {
            display: none;
        }
    }

    .page_home_filter.row.position-fixed {
        top: 64px;
        width: 100%;
        left: 10px;
        z-index: 1039;
        padding: 0 6rem;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
}

@media (max-width: 991px) {
    #page_home_products {
        div#home_filter {

            .filter-search,
            .filter-sort,
            .filter-show {
                padding-left: .5rem;
                padding-right: .5rem;
            }
        }

        .page_home_filter.row.position-fixed {
            padding: 0 2rem;
        }
    }
}


@media (max-width: 767px) {
    #page_home_products {
        div#home_filter {

            .filter-search,
            .filter-sort,
            .filter-show {
                padding-left: .5rem;
                padding-right: .5rem;
            }
        }

        .page_home_filter.row.position-fixed {
            padding: 0 .5rem;
        }
    }
}

@media (max-width: 576px) {
    #page_home_products {
        .page_home_filter.row.position-fixed {
            top: 64px;
        }

        .page_home_filter.row {
            padding: 0 .5rem;
            top: 64px;

            .col {
                flex: none;
                width: 50%;
            }
        }
    }
}